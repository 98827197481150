.ai-nam {
    background-clip: text;
    font-size: 2rem;
    font-weight: 700;
    
    -webkit-text-fill-color: transparent;
    display: inline-block;
    background-position: 100% 0%;
    background-image: linear-gradient(90deg, rgb(109, 237, 208), rgba(0, 123, 255, 0.6));
    background-size: auto;
    padding: 0.5rem 0rem;
  }
  
  .hhh {
    display: flex;
    align-items: center;
    margin: 10px 0;
    text-align: left;
  }
  
  .bot-message {
    flex-direction: row; /* Chatbot messages on the left */
  }
  
  .user-message {
    flex-direction: row-reverse; /* User messages on the right */
  }
  .suggestion-button{
    color: #000;
    padding: 10px;
    margin: 10px;
    border-radius: 30px;
    cursor: pointer;
  }
  .ai-chat-room {
    background-color: black;
    padding: 20px;
    overflow-y: auto;
    height: auto; /* Adjust height as needed */
    max-height: 400px;
  }
  
  .bubble {
    max-width: 70%;
    padding: 10px;
    border-radius: 15px;
    margin: 5px;
  }
  
  .bubble-light {
    background-color: #f1f0f0; /* Light bubble for bot */
    color: black;
  }
  
  .bubble-dark {
    background-color: #4a4a4a; /* Dark bubble for user */
    color: white;
  }
  
  .chat-input {
    width: 100%;
    padding: 0.6em 0.8em;
    border-radius: 30px;
    border: 1px solid transparent;
    font-size: 0.8em;
    color: white;
    background-color: black;
    box-sizing: border-box;
  }
  
  .b-container {
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 100%;
    margin: 20px auto; 
    padding: 3px 4px 3px 3px;
    border-radius: 30px;
    transition: all 0.4s ease;
  }
  
  .input-container {
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 90%; /* Adjust to 100% to allow responsiveness */
    margin: auto;
    padding: 3px 4px 3px 3px;
    background: linear-gradient(60deg, rgba(99, 35, 98, 1), rgba(180, 163, 9, 1), rgba(0, 0, 128, 1));
    border-radius: 30px;
    transition: all 0.4s ease;
  }
  
  .search-header__button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .search-container:hover {
    background: linear-gradient(190deg, #03a9f4, #f441a5);
    filter: blur(0.0em);
    transition: filter 0.4s ease;
  }
  .bubble-light .message-line{
    color: #000;
  }
  .bubble-dark .message-line{
  
      margin-bottom: 10px;
  line-height: 1.5; 
  }